.root {
    display: flex;
}
    .form-container {
        display: flex;
        flex-direction: row;
        flex: 2;
    }

    .form-column {
        padding: 15px;
        flex: 1;
    }

    .submit-button {
        flex: 2;
        //width: 100%;
    }

    .button-container {
        margin-bottom: 1rem;
    }    

    .table-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .input-container {
        display: flex;
        flex-direction: row;
        padding-top: 1rem;
        width: 100%;
        gap: .5rem;
    }

    .table-search-container {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    .table-picker {
        width: 100%;
    }

    .input-searchfield-container {
        padding-bottom: 0.5rem;
        width: 100%;
    }

    .table-name-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }


