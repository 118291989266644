.root {
    display: flex;
    flex-direction: column;
} 

.start {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
} 
