.submit-button {
  flex: 2;
}

.table-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.table-search-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.logo-wrapper {
  display: flex;
  margin-bottom: 1rem;
  border-radius: 8px;
}

.logo-label {
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
}

.input-searchfield-container {
  padding-bottom: 0.5rem;
  width: 100%;
}
