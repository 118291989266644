.submit-button {
    flex: 2;
  }
  
  .table-container {
    display: flex;
    white-space: nowrap;
  }
  
  .table-search-container {
    display: flex;
    flex-direction: column;
  }
  
  .logo-wrapper {
    display: flex;
    margin-bottom: 1rem;
    border-radius: 8px;
  }
  
  .logo-label {
    display: flex;
    flex-direction: row;
    column-gap: 3rem;
  }
  
  .table-container {
    padding-bottom: 0.5rem;
    width: 100%;    
  } 

  .input-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem !important;
  }