.root {
    display: flex;
    flex-direction: row;

    .container {
        flex: 1
    }

    .table-name-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .table-search-container {
        flex: 1;
        display: flex;
        flex-direction: row;
    } 

    .table-picker {
        width: 100%;
    }

    .input-searchfield-container {
        flex: 1;
        padding-right: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .table-container {
        flex: 1;
    }

    .green {
        color: green;
    }

    .orange {
        color: #ad4d00;
    }

    .red {
        color: #dc0000;
    }

    a, a:hover {
        color: inherit;
    }

}
