.root{
    .table-title{
        font-size: medium;
        font-weight: 500;
    }
    .header-wrapper{
        display: flex;
        margin-bottom: 1em;
        margin-top: 2rem;
        .organisation-name{
            font-size: large;
            font-weight: 500;
            width: 20%;
        }

        .navigation-items{
            display: flex;
        }

        .navigation-wrapper{
            width: 80%;
            display: flex;
            justify-content: flex-end;
            align-content: center;
            .navigation-item{
                margin-left: 5px;
            }
        }
    }
    .no-annualrealisation-yet-content-wrapper{
        width: 100%;
        text-align: center;
        justify-content: center;
        min-height: 100%;
        padding-top: 15%;
        padding-bottom: 15%;
    }
    .costs-expenses-forfait-button {
        margin-bottom: 1rem;
    }
}
