.root{
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    .title{
        font-size: 1em;
        font-weight: 500;
    }    
}

.table-picker {
    width: 100%;
}