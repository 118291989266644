@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less'; 

@layout-header-background: #ccc8ad;
@link-color: #393614;
@processing-color: #F1DA00;
@primary-color: #595633;
@menu-dark-color:#393614;

.ant-layout-header {
    padding-left: 2rem;
}