.debounceinput{
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
}

.filterInput {
    margin-top: .5rem;
}

.titleColumn {
    max-width: 8rem;
}

.error {
    border-color: red;
    color: red;
}

.debounceinputReadonly{
    @extend .debounceinput;
    padding-left: 1em;
    border: none;
    background-color: rgba(145, 145, 145, 0);
    outline: none;
}

.debounceinputError {
    @extend .debounceinput,  .error;
}

.projectinfo{
    .name{
        color: #929292;
        font-size: 0.8em;
    }
}

.actions{
    display: flex;
    justify-content: center;
}

.annualrealisationInputSheet {
    margin-bottom: 2rem;

    thead > tr > th {
        vertical-align: bottom;
        text-align: center;
        background: white;
    }
    thead > tr > th::before {
        display: none;
    }

    .application1 {
        background: rgba(145, 145, 145, 0.2) !important;
    }
    tbody tr:hover .application1 {
        background: rgba(145, 145, 145, 0.1) !important;
    }
    .application2 {
        background: rgba(145, 145, 145, 0.5) !important;
    }
    tbody tr:hover .application2 {
        background: rgba(145, 145, 145, 0.4) !important;
    }
    .application3 {
        background: rgba(145, 145, 145, 0.7) !important;
    }
    tbody tr:hover .application3 {
        background: rgba(145, 145, 145, 0.6) !important;
    }
    .application4 {
        background: rgba(145, 145, 145, 0.9) !important;
    }
    tbody tr:hover .application4 {
        background: rgba(145, 145, 145, 0.8) !important;
    }
    .total {
        background: #ccc8ad !important;
        line-height: 20px;
    }
    tbody tr:hover .total {
        background: rgba(#ccc8ad,0.9) !important;
    }

    .reportedTotal {
        background: #959072 !important;
        color: #fff;
    }
}
