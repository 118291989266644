.root {
    display: flex;
    flex-direction: row;
    min-height: 90vh;

    .container {
        flex: 1
    }

    .table-name-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .table-search-container {
        flex: 1;
        display: flex;
        flex-direction: row;
    }

    .input-searchfield-container {
        flex: 1;
        padding-right: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .table-container {
        flex: 1;
        tbody {
            cursor: pointer;
        }
    }

    .charts-container{
        display: flex;
        flex-direction: column;
        width: 25%;
        padding: 1em;
        margin-top: 1.6rem;
    }
}
