.root{
    display: flex;
    z-index: 1000;
}

.logo {    
    background: rgba(255, 255, 255, 0.938);        
    float: left;
    width: 3rem;
    height: 3rem;
    margin: 0.5rem 3rem 1rem 0;
  }

  .spinner {    
    top: 1.5rem;
    margin-right: 1rem;
    float: left;
    width: 0.5rem;
  }  

