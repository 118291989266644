.root {
    display: flex;

    .form-container {
        display: flex;
        flex-direction: row;
        flex: 2;
    }

    .form-column {
        padding: 15px;
        flex: 1;
    }

    .submit-button {
        flex: 2;
        padding-left: 50px;
        padding-right: 50px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .bsn-container {
        display: flex;
        flex-direction: column;
    }
}